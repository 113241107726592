@import "@/styles/_vars.scss";































































.data {
  margin: 80px 0;
  max-width: 700px;

  font-family: 'Roboto', sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;

  > * {
    margin: 8px;
  }
}

.last {
  margin-top: 60px;
}

input {
  width: 100%;
  border: 1px solid #DADADA;
  padding: 15px 22px;
  box-sizing: border-box;
}
