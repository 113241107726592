@import "@/styles/_vars.scss";



































































































































.container {
  min-width: 280px;
}

.form-calendar {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 100;
}

.overlay.open {
  display: block;
}

.wrapper {
  position: relative;
}

.trigger {
  border: #DADADA solid 1px;
  border-radius: 2px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.trigger > div {

}

.selected-date {
  padding: 16px 16px 16px 48px;
}

.arrow {
  padding: 16px 24px 16px 16px;

}

.arrow i {
  transition: all 0.5s;
  transform: rotate(180deg)
}

.arrow.open i {
  transform: rotate(0deg)
}

.calendar {
  color: #676767;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 8px;
  border: #DADADA solid 1px;
  border-radius: 2px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 200;
  pointer-events: none;
}

.calendar.open {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.year {
  display: flex;
  justify-content: space-between;
}

.year > * {
  padding: 8px 16px;
}

.year > div:hover {
  background-color: #F4F6F8;
}

.month {
  display: flex;
  justify-content: space-between;
}

.month > * {
  padding: 8px 16px;
}

.month > div:hover {
  background-color: #F4F6F8;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.days > * {
  padding: 2px;
  text-align: center;
}

.days > button {
  background-color: transparent;
}

.days > button:hover {
  background-color: #F4F6F8;
}

.item:hover {
  background-color: #f4f4f4;
  border: 1px solid #777777;
  border-radius: 2px;
}

button {
  color: #676767;
  border: none;
  background-color: transparent;
}

button:hover {
  background-color: #f4f4f4;
}

button:disabled {
  color: #DADADA;
}

