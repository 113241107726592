@import "@/styles/_vars.scss";























































































































































.panels {
  display: flex;
  justify-content: center;
  margin: 0 -16px;

  > * {
    padding: 32px;
    margin: 16px;
    border: 1px solid #DADADA;

    flex-grow: 1;
  }
}

.form-created {
  display: flex;
  flex-direction: column;

  > * {
    font-family: 'Roboto', sans-serif;
    margin: 16px 0;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #DADADA;
    padding: 15px 22px;
    margin: 8px 0;
  }
}

.form-completed {
  display: flex;
  flex-direction: column;

  > * {
    margin: 16px 0;
  }
}

.error {
  color: red;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
}

.details {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0 -8px;

  > div {
    margin: 8px;
  }
}

.check {
  margin: 16px 0;
  display: block;
}

.image_specification {
  display: flex;
  margin: 0 -12px;

  > * {
    margin: 24px 12px;
  }
}

.specification {
  font-family: 'Roboto', sans-serif;

  flex-grow: 1;

  display: grid;
  grid-template-columns: auto auto;
  align-content: start;

  > * {
    margin-bottom: 16px;
  }
}

.options {
  font-family: 'Roboto', sans-serif;
  color: #282828;

  display: grid;
  grid-template-columns: auto auto;

  > * {
    margin-bottom: 16px;
  }
}

.amount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #8D0AF4;

  margin-top: 85px;
}

