@import "@/styles/_vars.scss";




















































































































































.form-of-payment {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
}

.search {
  display: flex;

  button {
    white-space: nowrap;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error {
  color: red;
}

