@import "@/styles/_vars.scss";


































































.favorites {
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  > * {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #DADADA;
    margin: 12px 0;

    img {
      display: block;
      margin: 22px;
      width: 156px;
      height: 156px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin: 22px;

    .title {
      font-weight: bold;
      font-size: 1.5rem;
    }

    .operations {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 12px;
    }
  }

  .btn.favorite {
    padding: 8px 12px;
    color: red;
    background-color: rgb(242, 245, 249);

    &:hover {
      opacity: revert;
      background-color: rgb(226, 231, 236);
    }
  }
}

.unpublished {
  background: #eaeaea;
}
