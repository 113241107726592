@import "@/styles/_vars.scss";

























.header-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.splitter {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}

.right-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
