@import "@/styles/_vars.scss";























.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  background: url("@/assets/images/header-left-background.svg") no-repeat #F4F6F8;
}

.site-header {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.common-content {
  position: relative;
  top: 300px;
  display: flex;
  flex-direction: column;
}
