@import "@/styles/_vars.scss";











































































































































































































































































































.service-container {
  display: grid;
  grid-template-columns: 6fr 4fr;
  column-gap: 32px;

  > * {
    box-sizing: border-box;
  }
}

.header {
  grid-column: 1 / -1;
}

.service__image {
  padding: 16px;

  border: 1px solid #dadada;

  img {
    display: block;
    width: 100%;
  }
}

.service__specification {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 24px;
  row-gap: 21px;
  column-gap: 12px;

  font-family: "Roboto", sans-serif;
}

.service__specification-name {
  position: relative;

}

.service__specification-name::before {
  border-bottom: 1px solid rgb(218, 218, 218);
  bottom: .2em;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}

.service__specification-name > span {
  background: #fff;
  display: inline;
  padding-right: 3px;
  position: relative;
}

.service__parameters {
  min-width: 350px;
  padding: 26px 16px 26px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 1px solid #dadada;
}

.service__description-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

.service__amount-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 -8px;

  > div {
    margin: 8px;
  }
}

.service__amount-title {
  font-weight: bold;
  font-size: 1rem;
}

.service__amount {
  font-size: 2rem;
  font-weight: bold;
}

.service__amount_button {
  text-align: right;
}

.service__amount_favorite_buttons {
  display: flex;
  gap: 16px;

  button:first-child {
    flex-grow: 1;
  }

  button:last-child {
    padding: 8px 12px;
    color: red;
    background-color: rgb(242, 245, 249);

    &:hover {
      opacity: revert;
      background-color: rgb(226, 231, 236);
    }
  }
}

.service__description {
  grid-column: 1 / -1;

  font-family: 'Roboto', sans-serif;
  text-align: justify;

  margin-bottom: 48px;
}

.amount {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.amount input {
  box-sizing: border-box;
  border: #DADADA solid 1px;
  border-radius: 2px;
  padding: 14px 20px;
  text-align: center;
  max-width: 120px;
}

.showtimes {
  & > div {
    margin: 8px 0;
  }
}

@media screen and (max-width: $tablet-width) {
  .service-container {
    grid-template-columns: auto;
  }

  .service__image {
    border-bottom: none;
  }

  .service__parameters {
    border-top: none;
  }
}
