@import "@/styles/_vars.scss";













































































.orders {
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  > * {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #DADADA;
    margin: 12px 0;

    img {
      display: block;
      margin: 22px;
      width: 156px;
      height: 156px;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 22px;
}

.title_amount {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
}

.amount {
  font-weight: bold;
  font-size: 1.5rem;
  color: #8D0AF4;
  margin-left: 24px;
}

.operations {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.enumeration_status {
  display: flex;
}

.enumeration {
  font-family: 'Roboto', sans-serif;
  color: #BEBEBE;

  > div {
    margin-top: 8px;
  }
}

.status {

}

