@import "@/styles/_vars.scss";






































































































































.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F4F6F8 url("@/assets/images/boy.svg") no-repeat bottom 8px right 8px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 260px;
  position: relative;
}

.form {
  background-color: white;
  border: 1px solid #DADADA;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;

  > * {
    margin: 8px;
  }

  img {
    display: block;
    height: 15px;
    width: 20px;
    object-fit: contain;
  }

  a {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    color: black;
    border: 1px solid #C7C7C7;
    border-radius: 3px;
    background-color: white;
  }

  a.disabled {
    background-color: #f4f6f8;
    color: #262626;
  }

  a > * {
    margin: 6px;
  }
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  padding: 50px 24px 24px 24px;
}

.site-header {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.email-form {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;
  gap: 16px;
}

.email-form input {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  border: #DADADA solid 1px;
  border-radius: 2px;
  padding: 14px 20px;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 1.5rem;
  text-align: center;
  width: 350px;
}

.email-form a {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: #787878;
  text-decoration: none;
  padding-top: 32px;
  text-align: center;
}

.email-sent {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;
  gap: 8px;
}

.email-sent a {
  text-align: center;
}

