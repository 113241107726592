@import "@/styles/_vars.scss";























.breadcrumbs {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding-bottom: 16px;
  border-bottom: 1px solid #DADADA;

  a {
    margin: 0 8px;
    text-decoration: none;
    color: black;
  }
}

