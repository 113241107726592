@import '~modern-normalize';

@import '~@fortawesome/fontawesome-free/css/fontawesome.css';
@import '~@fortawesome/fontawesome-free/css/regular.css';
@import '~@fortawesome/fontawesome-free/css/solid.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

html,
body {
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(0.00227*100vw + 13.27px);
  color: #262626;
  min-width: 400px;
}

input[type=text], input[type=password] {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #DADADA;
  padding: 15px 22px;
}

.container {
  max-width: $container-width;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 40px;
}

.header {
  font-style: normal;
  font-size: 2.25rem;
  font-weight: bold;
  margin: 32px 0;
}

.btn {
  display: inline-block;
  padding: 12px 62px;
  background-color: #8D0AF4;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 2px;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    background-color: #D6C8FF;
  }
}

.select {
  font-family: 'Roboto', sans-serif;
  color: #676767;

  appearance: none;
  border: 1px solid #DADADA;
  border-radius: 2px;
  padding: 14px 50px 14px 14px;
  width: 100%;

  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.45593 8.27079L0.225371 2.04019C-0.0751238 1.7397 -0.0751238 1.25252 0.225371 0.952059L0.952066 0.225364C1.25205 -0.0746183 1.73823 -0.0751953 2.03892 0.224081L7.00002 5.16194L11.9611 0.224081C12.2618 -0.0751953 12.748 -0.0746183 13.0479 0.225364L13.7746 0.952059C14.0751 1.25255 14.0751 1.73973 13.7746 2.04019L7.54407 8.27076C7.24361 8.57125 6.75643 8.57125 6.45593 8.27079Z' fill='%23777777'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 14px 8.5px;

  option {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 14px;
  }
}

.link {
  font-family: "Roboto", sans-serif;
  color: #8D0AF4;
  text-decoration: none;
}

@media screen and (max-width: $tablet-width) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: $mobile-width) {
  html,body {
    font-size: 14px;
  }
}

@media screen and (min-width: $container-width) {
  html,body {
    font-size: 16px;
  }
}