@import "@/styles/_vars.scss";













































pre {
  margin: 0;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.error {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.value {
  font-family: 'Roboto', sans-serif;
}

.title {
  margin-top: 16px;
  font-weight: bold;
}

