.shopfront-menu-wrapper {
  position: absolute;
  top: 56px;
  height: 56px;
  width: 100%;
  background-color: white;
}

.shopfront-menu {
  box-sizing: border-box;
  height: 56px;
  display: flex;
  margin: 0;
  justify-content: flex-end;
  align-items: center;

  > li {
    list-style-type: none;
    margin: 0 16px;
  }
}

.shopfront-menu-logo {
  img {
    height: 17px;
  }
}

.shopfront-recommendation-wrapper {
  background-color: #F4F6F8;

  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.shopfront-recommendation-header {
  font-size: 2rem;
  font-weight: bold;
}

.shopfront-recommendation-tiles {
  display: flex;
  padding: 32px 0;
  overflow: hidden;
}

.shopfront-recommendation-tile {
  display: block;
  text-decoration: none;
  color: black;
  flex-shrink: 0;
  position: relative;
  width: 168px;
  height: 168px;
  margin: 0 11px;
  background: #FFFFFF;
  border: 2px solid #e5e5e5;
  border-radius: 3px;

  > h3 {
    margin: 18px 24px;
    font-size: 1.125rem;
    overflow: hidden;
  }

  > img {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: 136px;
    height: 104px;
    object-fit: cover;
  }
}

.shopfront-services-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 22px 0;
}

.shopfront-services-path {
  padding: 16px 0;
  margin-bottom: 8px;
  color: rgb(51, 51, 51);

  a {
    color: inherit;
  }
}

.shopfront-services-tiles {
  display: flex;
  margin: 0 -8px 80px;
  flex-wrap: wrap;
}

.shopfront-services-tile {
  flex-shrink: 0;
  width: 264px;
  height: 264px;
  border: 2px solid #e5e5e5;
  border-radius: 3px;
  margin: 10px 8px;

  > a {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 24px 24px 0 24px;

    text-decoration: none;
    color: black;
  }

  > a > h3 {
    margin: 0;
    font-size: 1.125rem;
    overflow: hidden;
  }

  > a > img {
    position: absolute;
    right: 24px;
    bottom: 0;
    width: 216px;
    height: 160px;
    object-fit: cover;
  }
}

.shopfront-content {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $tablet-width) {
  .shopfront-catalog-header {
    height: 300px;
  }

  .shopfront-recommendation-header {
    display: none;
  }

  .shopfront-recommendation-tiles {
    padding-top: 12px;
  }

  .shopfront-recommendation-tile {
    width: 120px;
    height: 120px;
    margin: 0 7px;

    > h3 {
      margin: 6px 9px;
      font-size: 0.875rem;
    }

    > img {
      right: 8px;
      width: 104px;
      height: 80px;
    }
  }

  .shopfront-services-header {
    font-size: 1.25rem;
  }

  .shopfront-services-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  .shopfront-services-tile {
    width: 170px;
    height: 170px;

    > a {
      padding: 15px 15px 0 15px;

      > h3 {
        font-size: 0.875rem;
      }

      > img {
        width: 140px;
        height: 104px;
        right: 15px;
      }
    }
  }
}