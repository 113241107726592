.shop-header {
  font-size: calc(2.5vw + 10.00px);
  letter-spacing: -0.015em;
  line-height: calc(2.95vw + 12.55px);
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  height: calc(5.45vw + 34.55px);
  margin-bottom: calc(1.70vw + 9.55px);
  margin-right: calc(23.86vw + 33.64px);
}

.shop-content {
  position: relative;
  top: calc(5.36vw + 50.86px);
  display: flex;
  flex-direction: column;
}

.shop-groups-wrapper {
  margin-bottom: 8px;
}

.shop-groups {
  display: flex;
  overflow-x: hidden;
  gap: calc(2.00vw + 8.00px);
}

.shop-group {
  margin-top: calc(1.00vw + 4.00px);
  margin-bottom: calc(1.00vw + 4.00px);
  border: 1px solid #DADADA;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: white;

  a {
    width: calc(18.07vw + 38.18px);
    display: block;
    text-decoration: none;
    color: black;
  }

  img {
    width: 100%;
    height: calc(13.41vw + 29.09px);
    object-fit: cover;
  }

  &__text {
    margin: calc(0.68vw + 9.82px) calc(0.91vw + 11.09px);
    font-weight: 600;
    font-size: calc(1.36vw + 5.64px);
  }
}

.shop-services {
  display: flex;
  flex-direction: column;
}

.shop-services-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    flex-grow: 1;
  }
}

.shop-filters {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;

  margin: 0 -8px;

  > div {
    margin: 8px;
  }
}

.shop-clear-filter-link {
  text-align: right;
}

.shop-service-content {
  padding-top: calc(19.37vw + 32.82px);
}

.shop-top-content {
  min-height: calc(25.83vw + 78.76px);
}

@media screen and (max-width: $mobile-width) {
  .shop-header {
    font-size: 18px;
    line-height: 22px;
    margin-right: 110px;
    margin-bottom: 15px;
    height: 52px;

  }

  .shop-content {
    top: 68px;
  }

  .shop-group {
    a {
      width: 96px;
    }

    img {
      height: 72px;
    }

    &__text {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: $tablet-width) {
  .shop-content {
    top: 160px;
  }

  .shop-service-content {
    padding-top: calc(24.04vw - 68.46px);
  }

  .shop-top-content {
    min-height: calc(33.65vw - 53.85px);
  }
}

@media screen and (min-width: $container-width) {
  .shop-header {
    font-size: 40px;
    line-height: 48px;
    margin-right: 320px;
    margin-bottom: 30px;
    height: 100px;
  }

  .shop-group {
    a {
      width: 255px;
    }

    img {
      height: 190px;
    }

    &__text {
      margin: 18px 22px;
      font-size: 22px;
    }
  }

  .shop-service-content {
    padding-top: 220px;
  }

  .shop-top-content {
    min-height: 350px;
  }
}