@import "@/styles/_vars.scss";





















































.my-office__container {
  display: grid;
  grid-template-columns: 256px auto;
  column-gap: 32px;
}

.submenu {
  padding: 16px;
}

.title {
  text-decoration: none;
  color: #8E8E8E;
  display: flex;
  align-items: center;
  font-weight: 700;
}

a.title:hover {
  color: black;
}

a.title:hover svg path {
  fill: black;
}

.title > svg {
  flex-shrink: 0;
}

.title > span {
  margin-left: 12px;
}

.page {
  display: block;
  text-decoration: none;
  color: #8E8E8E;
  margin-left: 36px;
  margin-top: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.page:hover {
  color: black;
}

.router-link-active {
  color: black;
}

.title.router-link-active svg path {
  fill: black;
}

@media screen and (max-width: $tablet-width) {
  .my-office__container {
    grid-template-columns: auto;
    column-gap: 32px;
  }

  .menu {
    display: none;
  }
}

