@import "@/styles/_vars.scss";


























.dashboard {
  display: flex;
  min-height: 100%;
}

.menu {
  flex: 0 0 250px;
  background-color: #313a46;
  color: white;
}

.menu-title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  line-height: 1.5;
  text-decoration: none;
  color: white;
  display: block;
}

.menu-list {
  list-style-type: none;

  a {
    color: rgb(131, 145, 162);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: rgb(188, 206, 228)
    }

    &.router-link-active {
      color: white;
    }
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.content {
  flex: 1 1 auto;
}
