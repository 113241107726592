@import "@/styles/_vars.scss";

































































































body {
  margin: 0
}

img {
  border-style: none;
}

.wrapper {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: $container-width;
}

@keyframes fade-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.buffer {
  position: relative;
  width: 100%;
  height: calc(100vw / 2);
  max-height: 600px;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  > img.front {
    animation: fade-out 1s;
    opacity: 1;
  }

  > img.back {
    animation: fade-in 1s;
    opacity: 0;
  }
}

.left-button {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.right-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  color: #ffffff;
  font-size: 2rem;
  text-decoration: none;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.dots {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #F4F6F8;

  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.dot {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #BEBEBE;
  margin: 5px;
  border-radius: 50%;
}

.dot.selected-image {
  background-color: #841DD4;
}

.dot:hover {
  background-color: #841DD4;
}

