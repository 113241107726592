@import "@/styles/_vars.scss";



























































footer {
  background-color: #ECECEC;
  padding-top: 26px;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  color: black;
}

a {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #8E8E8E;
  line-height: 24px;
}

main {
  display: flex;
  justify-content: space-between;
  margin: 20px -8px 48px;

  > div {
    margin: 8px;
    flex-grow: 1;
  }
}

.general-info {
  display: flex;
  justify-content: space-between;
  margin: 0 -16px 64px;

  > div {
    margin: 0 16px;
  }

  > div > div {
    margin: 8px 0;
  }
}

.subsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 56px -8px;

  > div {
    margin: 8px 8px;
  }
}

@media (max-width: $tablet-width) {
  .general-info {
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .subsection {
    flex-direction: column;
  }
}


