@import "@/styles/_vars.scss";

























































































































































.tiles {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
}

.tile {
  display: flex;
  align-items: stretch;
  border: 1px solid #DADADA;
}

.tile__image {
  display: block;
  width: calc(0.14 * 100vw + 36.36px);
  height: calc(0.14 * 100vw + 36.36px);
  object-fit: cover;
}

.tile__header {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  height: 100%;
  overflow-y: hidden;
}

.tile__content {
  flex-grow: 1;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  padding: 24px;
}

.tile__amount {
  justify-self: right;

  font-weight: bold;
  font-size: 1.3rem;
  color: #8D0AF4;
}

.tile__description {
  font-family: "Roboto", sans-serif;
  color: #676767;
}

.tile__actions {
  justify-self: right;
  align-self: end;
}

@media screen and (max-width: $mobile-width) {
  .tile__image {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: $tablet-width) {
  .tile__image {
    margin: 9px 0 9px 9px;
  }

  .tile__content {
    grid-template-columns: auto;
    gap: 9px;
  }

  .tile__amount {
    justify-self: left;
  }

  .tile__actions {
    justify-self: left;
  }
}


@media screen and (min-width: $container-width) {
  .tile__image {
    width: 200px;
    height: 200px;
  }
}
