@import "@/styles/_vars.scss";






























.header-container {
  position: absolute;
  top: 56px;
  width: 100%;
}

.header-wrapper {
  width: 100%;
}

.site-header {
  height: 56px;
  width: 100%;

  background-color: white;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  position: relative;
  display: block;
}

@media screen and (max-width: $container-width) {
  .logo {
    width: 150px;
    height: 24px;
  }
}

@media screen and (max-width: $tablet-width) {
  .header-container {
    top:0;
  }
}


